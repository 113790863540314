import React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { memo } from 'react'
import { Paper, Tooltip } from '@mui/material'
import useMedia from '~/hooks/useMedia'
import { QuoteBoxToolTip } from '~/modules/SDK/socket/QuoteBoxToolTip'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { fontWeight600 } from '~/css/font'
import { flex, jc } from '~/modules/AppLayout/FlexGridCss'
import { BLACK } from '~/modules/AppLayout/Colors'
import { useMount, useUnmount } from 'react-use'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'

export const SignalrOsFuturesQuote = memo<
  ReactProps<{
    symbol: Socket.SymbolString
    rootCss?: SerializedStyles
    isDarkMode?: boolean
    baseOnTodayOpen?: boolean
    intraday?: boolean
  }>
>(function SignalrOsFuturesQuote(props) {
  const { isPhone, isPc } = useMedia()
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol])
  const quoteChanges = processQuoteToReadable(
    symbolInfo as Socket.ValueOfOHLC,
    props.baseOnTodayOpen,
  )

  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)
  const boxRootCss = css`
    ${flex.v.default};
    ${jc.spaceBetween}
    border-radius: 8px;
    width: ${isPhone ? '48%' : '180px'};
    height: 64px;
    margin: ${isPc ? '4px 8px' : '2px'};
    padding: ${isPhone ? '4px' : '4px 8px'};
    color: white;
    ${props.rootCss}
  `
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc')
  })

  if (!symbolInfo) return null
  const symbolDisplayName = dictionary[symbolInfo.symbol] || symbolInfo.symbol
  const isLongSymbol = symbolDisplayName.length > 3
  return (
    <Tooltip
      title={
        <QuoteBoxToolTip
          enableChart={true}
          enableVolume={true}
          enableTradeValue={false}
          data={symbolInfo}
          quoteChanges={quoteChanges}
          intraday={true}
        />
      }
    >
      <Paper
        elevation={8}
        css={css`
          ${boxRootCss}
          background: ${getFuturesPercentageDeltaColor(quoteChanges?.closeChangePercent)};
          text-shadow: ${BLACK} 0.1em 0.1em 0.2em;
          ${quoteAnimation};
        `}
      >
        <div
          css={css`
            ${flex.h.crossCenter};
            ${jc.spaceBetween}
            color: white;
            * {
              margin: 0;
            }
            & > *:nth-of-type(1) {
              font-size: ${isLongSymbol ? (isPhone ? 0.8 : 1) : 1.2}em;
              ${fontWeight600};
            }
            & > *:nth-of-type(2) {
              font-size: ${isLongSymbol ? (isPhone ? 0.8 : 0.9) : 1.2}em;
            }
          `}
        >
          <p>{symbolDisplayName}</p>
          <p>${symbolInfo.close}</p>
        </div>
        <div
          css={css`
            ${flex.h.default};
            ${jc.spaceBetween}
            * {
              margin: 0;
            }
            & > * {
              font-size: ${isLongSymbol && isPhone ? 1 : 1.2}em;
              margin-right: 16px;
              flex: 1;
            }
            & > *:nth-of-type(2) {
              color: white;
              font-size: 1.2em;
              align-self: flex-end;
              margin-right: 0;
              text-align: right;
            }
          `}
        >
          <p>{quoteChanges.closeChangePercentString}</p>
          <p>{quoteChanges.deltaChangeString}</p>
        </div>
      </Paper>
    </Tooltip>
  )
})

/** 期貨 指標監控定義色 */
export const getFuturesPercentageDeltaColor = (closePercentage: number) => {
  const color = '#414554'

  if (closePercentage > 0) {
    if (closePercentage > 2) return '#f63538'
    if (closePercentage > 1) return '#bf4045'
    if (closePercentage > 0.3) return '#8b444e'
  } else {
    if (closePercentage < -2) return '#30cc5a'
    if (closePercentage < -1) return '#2f9e4f'
    if (closePercentage < -0.3) return '#35764e'
  }

  return color
}
